.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* -----------------------Common CSS------------------ */
.tw_center {
  text-align: -webkit-center;
}

.borderBtmOrangeClr {
  border-bottom: 1px solid #f4670e !important;
}

.zindexN1 {
  z-index: -1;
}

.mouse {
  cursor: pointer;
}

.bggray {
  background-color: #F8F8F8;
}

.clrgray {
  color: #999;
}

.line_clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line_clamp4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.redClr {
  color: #ac0102;
}

.orangeClr {
  color: #f4670e;
}

.bgOrangeClr {
  background-color: #f4670e;
}

.blueClr {
  color: #002147;
}
.lightBlueClr {
  color: #0b0080;
}

.blueBgClr {
  background-color: #0a149d;
}

.mouseOver {
  cursor: pointer;
}

.w115 {
  width: 115px;
}

.w120 {
  width: 120px;
}

.h40 {
  height: 40px;
}
.h520 {
  height: 520px;
}

.text_justify {
  text-align: justify;
}

.mutedClr {
  color: #5d5d5d;
}

.lh_1_8 {
  line-height: 1.8;
}

.w300 {
  width: 300px;
}

/* -----------------------BootStrap------------------------ */
.form-select:focus {
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: none !important;
}

.form-control:focus {
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: none !important;
}

.was-validated .form-control:invalid {
  background-image: none !important;
}

.was-validated .form-control:valid {
  background-image: none !important;
}

.was-validated .form-select:valid:not([multiple]):not([size]) {
  background-image: none !important;
}

.was-validated .form-select:invalid:not([multiple]):not([size]) {
  background-image: none !important;
}

.form-control {
  padding: .375rem !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
/* .table>:not(caption)>*>*{
  padding: 0 .25rem!important;
} */

/* -----------------------header------------------------ */
.logo {
  height: 30px;
  width: 100px;
}

.headerbg {
  background-color: maroon;
}

.headerA {
  color: white;
  margin-top: 10px;
  padding: 15px;
}

.headerLink {
  margin: 10px;
  color: white;
  text-align: center;
  text-decoration: none;
}

.schins {
  background: darkgray;
  padding-top: 16px;
  text-align: center;
  height: 50px;
  width: 348px;
  flex-wrap: wrap;
}

.schoolActive {
  border-bottom-right-radius: 50px;
  background: darkgray;
  width: 182px;
  text-align: center;
  padding: 8px;
  position: absolute;
  top: 1%;
}

.institueFFF {
  background-color: #fff;
  padding: 0px 46px;
  border-top-left-radius: 29px;
  position: absolute;
  right: 0;
  top: 3px;
  width: 173px;
  padding-top: 7px;
}

.si {
  display: inline-flex;
  text-align: center;
  background-color: #d9d9d9;
  padding-top: 3px;
  width: 360px;
  margin-bottom: 17px;
  height: 20px;
}

.sactive {
  width: 184px;
  background-color: #d9d9d9;
  border-bottom-right-radius: 50px;
  text-align: center;
  padding: 8px;
  position: absolute;
  color: #6b6b6b;
  font-weight: 600;
}

.insFFF {
  width: 184px;
  background-color: #fff;
  padding: 0px 46px;
  border-top-left-radius: 50px;
  position: absolute;
  margin-left: 177px;
  padding-top: 7px;
  color: #333;
  font-weight: 600;
}


.selectRow {
  text-align: center;
  background-color: #d9d9d9;
  padding-top: 3px;
  margin-bottom: 23px;
  height: 20px;
}

.selectSchool {
  background-color: #d9d9d9;
  border-bottom-right-radius: 50px;
  text-align: center;
  padding: 8px;
  color: #6b6b6b;
  font-weight: 600;
  cursor: pointer;
}

.selectInstituteActive {
  background-color: #fff;
  padding: 0px 46px;
  border-top-left-radius: 50px;
  margin-left: -10px;
  padding-top: 7px;
  color: #333;
  font-weight: 600;
}

.selectSchoolActive {
  background-color: #fff;
  border-top-right-radius: 50px;
  text-align: center;
  padding: 8px;
  color: #333;
  font-weight: 600;
}

.selectInstitute {
  background-color: #d9d9d9;
  padding: 0px 46px;
  border-bottom-left-radius: 50px;
  margin-left: -10px;
  padding-top: 7px;
  color: #6b6b6b;
  font-weight: 600;
  cursor: pointer;
}

/* -------------------------------------Home-------------------------------- */
.sliderImg {
  max-height: 600px;
  object-fit: cover;
}

/* Latest News */
.boxShadow {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 1.41437px 0.70718px rgb(0 0 0 / 20%);
}

.newImg {
  min-height: 100px;
  min-width: 150px;
  object-fit: cover;
}


.viewAllBtn {
  text-align: center;
  padding: 12px 34px;
  font-size: 14px;
  display: inline-block;
  border: none;
  font-weight: 500;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  line-height: 1.5;
  background-color: #002147;
  color: #fff;
}

.viewAllBtn:hover {
  background-color: #f4670e;
}

.rtin-calender {
  background-color: #fdc800;
  position: relative;
  padding-top: 20px;
  padding-right: 0px;
  text-align: center;
  min-width: 100px;
  height: fit-content;
}

/* .rtin-calender::before {
  color: #002147;
  content: "\f111";
  position: absolute;
  top: 8px;
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  line-height: 1;
}
.rtin-calender::after {
  color: #002147;
  content: "\f111";
  position: absolute;
  top: 8px;
  font-size: 14px;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  line-height: 1;
} */

.eventYear {
  background-color: #002147;
  color: #fff;
  padding: 5px 10px;
}

/* ---------Welcome------ */
.welcome-icon {
  /* font-size: 100px;
  background: #eeeeee;
  padding: 20px;
  border-radius: 50%; */
  height: 100px;
  width: 100px;
  font-size: 50px;
  background: #eee;
  border-radius: 50%;
  line-height: 88px;
}

.welcome-icon:hover {
  background-color: #ac0102;
  color: #fff;
}

.welcome-heading {
  font-size: 22px;
  line-height: 1.5;
  margin: 10px 0;
  color: #002147;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

/* -------------------Counter----------------------- */
.counterBg {
  background-color: #002147;
  color: #fff;
  padding: 4rem 0;
}

.counterNo {
  font-size: 80px;
  font-weight: 600;
  border-bottom: 1px solid #f4670e;
  width: fit-content;
}

.counterText {
  font-size: 20px;
}

/* -------------------Footer----------------------- */
.achiversLogo {
  height: 40px;
  width: 140px;
}

.thim_social {
  font-size: 16px;
  width: 32px;
  height: 32px;
  line-height: 27px;
  border: 1px solid #999;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
  cursor: pointer;
  color: #999;
}

.thim_social.thim_socialFa {
  color: #999;
}

.thim_social.thim_socialFa:hover {
  background-color: #23527c;
  border: 1px solid #23527c;
  color: #fff !important;
}

.thim_social.thim_socialTw {
  color: #999;
}

.thim_socialTw:hover {
  background-color: #00aced;
  border: 1px solid #00aced;
  color: #fff !important;
}

.thim_social.thim_socialIn {
  color: #999;
}

.thim_socialIn:hover {
  background-color: #C13584;
  border: 1px solid #C13584;
  color: #fff !important;
}

.thim_social.thim_socialWa {
  color: #999;
}

.thim_socialWa:hover {
  background-color: #25D366;
  border: 1px solid #25D366;
  color: #fff !important;
}

.footerLink {
  color: #fff;
  text-decoration: none;
}

.footerLink:hover {
  color: #f4670e;
}

.subscribeBtn {
  background-color: #f4670e;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  width: 100%;
}

.subscribeBtn:hover {
  background-color: #ac0102;
}

/* --------------------------About--------------------------- */
.homeBreadcrumb {
  color: #6b6b6b;
  font-weight: 300;
}

.activeBerdcrumb {
  color: #f4670e;
  font-weight: 300;
}

.tabBG {
  background-color: #f3f3f3;
  padding: 12px !important;
}

.tabBG:hover {
  border-left: 2px solid #f4670e;
  cursor: pointer;
}

.tabBG.nav-link.active {
  border-left: 2px solid #f4670e;
  background-color: #FFF !important;
  color: #f4670e;

}

/* --------------------------Courses--------------------------- */
.main_heading {
  color: #5d5d5d;
  font-family: Roboto, sans-serif !important;
  font-size: 54px;
  font-weight: 700;
  letter-spacing: -4px;
  line-height: 62px;
  padding: 40px 0;
  position: relative;
  text-align: center;
  text-transform: capitalize;
  text-transform: uppercase;
}

.courseImg {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.courseDesc {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 95px;
  padding-bottom: 0 !important;
  text-align: justify;
  color: #5d5d5d;
}

.chatBtn {
  background-color: #25D366;
  color: #000;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  width: 100%;
}

/* --------------------------Courses Details--------------------------- */
.course_details {
  padding: 30px;
  background-color: #fff;
}

.courseDImg {
  max-height: 400px;
  width: 100%;
  object-fit: cover;
}

.cd_steps {
  padding: 10px 0;
  background-color: #dee2e6;
  color: #000;
  cursor: pointer;
}

.cd_steps_active {
  padding: 10px 0;
  background-color: #002147;
  color: #fff;
}

.cdSocialIcon {
  font-size: 30px;
  padding: 8px;
  background: #fdc800;
  color: #fff;
}

.cdSyllabus {
  padding: 10px 0;
  background-color: #dee2e6;
  color: #000;
  cursor: pointer;
}


/* ------------------------Conatct ----------------------- */
.contact_box {
  height: 550px;
}

.contact_SocialIcon {
  font-size: 30px;
  padding: 5px;
  color: #5d5d5d;
  border: 1px solid #5d5d5d;
}

.contact_SocialIcon.csFa:hover {
  background-color: #23527c;
  border: 1px solid #23527c;
  color: #fff !important;
}

.contact_SocialIcon.csTa:hover {
  background-color: #00aced;
  border: 1px solid #00aced;
  color: #fff !important;
}

.contact_SocialIcon.csLi:hover {
  background-color: #00aced;
  border: 1px solid #00aced;
  color: #fff !important;
}

.contact_SocialIcon.csIg:hover {
  background-color: #C13584;
  border: 1px solid #C13584;
  color: #fff !important;
}

.contact_SocialIcon.csWa:hover {
  background-color: #25D366;
  border: 1px solid #25D366;
  color: #fff !important;
}

.contactBtn {
  background-color: #921616;
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.contactBtn:hover {
  background-color: #f4670e;
}

.contactBtnMt {
  margin-top: 4rem;
}

/* ----------------------------------------Registration Form-------------------- */
.regP {
  padding: 3rem !important;
}

.regPhoto {
  height: 200px;
  width: 200px;
  border: dashed;
  text-align: center;
  line-height: 200px;
  cursor: pointer;
}

.isPhoto {
  height: 200px;
  width: 200px;
  border: dashed;
  text-align: center;
  line-height: 200px;
  cursor: pointer;
}

.isPhotoX {
  position: absolute;
  margin-left: 170px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
}

.viewFormTDW {
  width: 300px;
}

/* -------------------------Parent Section--------------------- */
/* Left Menu */
.leftMenuBtn {
  border: none;
  outline: none;
  padding: 10px 16px;
  background-color: #f1f1f1;
  cursor: pointer;
  font-size: 18px;
}

.leftMenuBtn:hover {
  background-color: #666;
  color: white;
}










/* --------------------------Media CSS----------------------- */
@media screen and (max-width: 1199px) {
  .counterNo {
    font-size: 60px;
  }

  .counterText {
    font-size: 18px;
  }

  .chatBtn {
    font-size: 12px;
    padding: 10px 0;
  }

  .subscribeBtn {
    font-size: 12px;
    padding: 10px 0;
  }
}

@media screen and (max-width: 991px) {
  .main_heading {
    font-size: 40px;
    letter-spacing: -1px;
  }

  .counterNo {
    font-size: 50px;
  }

  .counterText {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .main_heading {
    font-size: 30px;
    letter-spacing: -1px;
  }

  .counterText {
    text-align: center;
  }

  .feeTableH {
    font-size: 14px;
  }
}

@media screen and (max-width: 525px) {
  .main_heading {
    font-size: 20px;
    letter-spacing: -1px;
  }

  .regPhoto {
    height: 150px;
    width: 150px;
    line-height: 150px;
  }

  .isPhoto {
    height: 150px;
    width: 150px;
    line-height: 150px;
  }

  .isPhotoX {
    margin-left: 170px;
  }

}

@media screen and (max-width: 510px) {
  .feeTableH {
    font-size: 12px;
  }

  .cd_steps {
    font-size: 12px;
    padding: auto;
  }

  .cd_steps_active {
    font-size: 12px;
    padding: auto;
  }

  .regP {
    padding: 1.5rem !important;
  }
}

@media screen and (max-width: 410px) {
  .regP {
    padding: 1rem !important;
  }

  .regPhoto {
    height: 120px;
    width: 120px;
    line-height: 120px;
    font-size: 12px;
  }

  .isPhoto {
    height: 120px;
    width: 120px;
    line-height: 120px;
  }

  .isPhotoX {
    margin-left: 170px;
  }

}

@media screen and (max-width: 360px) {
  .regP {
    padding: .75rem !important;
  }

  .regPhoto {
    height: 100px;
    width: 100px;
    line-height: 100px;
    font-size: 10px;
  }

  .isPhoto {
    height: 100px;
    width: 100px;
    line-height: 100px;
  }

  .isPhotoX {
    margin-left: 170px;
  }

}